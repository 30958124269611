import { parseISO } from 'date-fns';
import { Field, Form, Formik, FormikValues, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Button } from '../../../components/button/Button';
import { CheckField } from '../../../components/form/check-field/CheckField';
import { DatePicker } from '../../../components/form/date-picker/DatePicker';
import { FieldLabel } from '../../../components/form/field-label/FieldLabel';
import { FileUpload } from '../../../components/form/file-upload/FileUpload';
import MultiField from '../../../components/form/multi-field/MultiField';
import { RadioField } from '../../../components/form/radio-field/RadioField';
import { RichTextField } from '../../../components/form/rich-text-field/RichTextField';
import { SelectField } from '../../../components/form/select-field/SelectField';
import { TextField } from '../../../components/form/text-field/TextField';
import MasonryContentBlock from '../../../components/masonry/MasonryContentBlock';
import { NAV_ROUTES } from '../../../Routes';
import {
  PROGRAMME_STATUS,
  TRANSLATION_STATUS,
  useAddProgrammeMutation,
  useDeleteFileMutation,
  useGetAllProgrammeTranslationsQuery,
  useGetProgrammeDataQuery,
  useUploadFileMutation,
  useWhoAmIQuery,
} from '../../../store/api/generalApi';
import { ROLE } from '../../../utils/permissions';
import { useRoleGuard } from '../../../utils/roleGuard';
import {
  useCountryOptions,
  useProgrammeCategoryOptions,
  useProgrammeStatusOptions,
  useProgrammeTargetOptions,
  useProgrammeTopicsOptions,
  useProgrammeTypeOfActivityOptions,
  useProgrammeTypeOptions,
} from '../../../utils/translation';

import './ProgrammeForm.css';

export enum PROGRAMME_FORM_FIELDS {
  Language = 'language',
  DisplayTitle = 'display-title',
  ReferenceTitle = 'reference-title',
  ProgrammePromoter = 'programme-promoter',
  PreviewText = 'preview-text',
  ProgrammeDescription = 'programme-description',
  ProgrammeCategory = 'programme-category',
  ProgrammeTopics = 'programme-topics',
  ProgrammeType = 'programme-type',
  ProgrammeTarget = 'programme-target',
  EligibleAgeGroupStart = 'eligible-age-group-start',
  EligibleAgeGroupEnd = 'eligible-age-group-end',
  ProgrammeStartDate = 'programme-start-date',
  ProgrammeEndDate = 'programme-end-date',
  DestinationCountries = 'destination-countries',
  EligibleCountries = 'eligible-countries',
  DeadlinesStart = 'deadlines-start',
  DeadlinesEnd = 'deadlines-end',
  IsTranslatable = 'is-translatable',
  TypeOfActivity = 'type-of-activity',
  Status = 'status',
  MarkTranslationsOutdated = 'mark-translations-outdated',
  Image = 'image',
}

const validationSchema = yup.object().shape({
  [PROGRAMME_FORM_FIELDS.Language]: yup.string(),
  [PROGRAMME_FORM_FIELDS.DisplayTitle]: yup.string().required(),
  [PROGRAMME_FORM_FIELDS.ReferenceTitle]: yup.string(),
  [PROGRAMME_FORM_FIELDS.ProgrammePromoter]: yup.string(),
  [PROGRAMME_FORM_FIELDS.PreviewText]: yup.string(),
  [PROGRAMME_FORM_FIELDS.ProgrammeDescription]: yup.string(),
  [PROGRAMME_FORM_FIELDS.ProgrammeCategory]: yup.string(),
  [PROGRAMME_FORM_FIELDS.ProgrammeTopics]: yup.array(),
  [PROGRAMME_FORM_FIELDS.ProgrammeType]: yup.string(),
  [PROGRAMME_FORM_FIELDS.ProgrammeTarget]: yup.string(),
  [PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart]: yup.number(),
  [PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd]: yup.number(),
  [PROGRAMME_FORM_FIELDS.ProgrammeStartDate]: yup.string(),
  [PROGRAMME_FORM_FIELDS.ProgrammeEndDate]: yup.string(),
  [PROGRAMME_FORM_FIELDS.DestinationCountries]: yup.array(),
  [PROGRAMME_FORM_FIELDS.EligibleCountries]: yup.array(),
  [PROGRAMME_FORM_FIELDS.DeadlinesStart]: yup.array(),
  [PROGRAMME_FORM_FIELDS.DeadlinesEnd]: yup.string(),
  [PROGRAMME_FORM_FIELDS.IsTranslatable]: yup.boolean(),
  [PROGRAMME_FORM_FIELDS.TypeOfActivity]: yup.string(),
  [PROGRAMME_FORM_FIELDS.Status]: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required('Status is a required field'),
  [PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated]: yup.array(),
  [PROGRAMME_FORM_FIELDS.Image]: yup.mixed(),
});

export interface ProgrammeFormRouteProps {
  id?: string;
  language?: string;
}

const ProgrammeForm = (props: ProgrammeFormRouteProps) => {
  const [addProgramme] = useAddProgrammeMutation();
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();
  const { data: whoAmI } = useWhoAmIQuery();

  const { data: programmeTranslations = {} } = useGetAllProgrammeTranslationsQuery({ id: props.id || '' });
  const defaultLanguage = Object.keys(programmeTranslations).find(
    (langcode) => programmeTranslations[langcode].isDefault === 1
  );
  const currentLanguage = props.language ? props.language.toLowerCase() : defaultLanguage || '';

  const { data: programmeData } = useGetProgrammeDataQuery(
    {
      id: props.id,
      language:
        programmeTranslations[currentLanguage]?.status !== TRANSLATION_STATUS.NOT_TRANSLATED
          ? currentLanguage
          : defaultLanguage,
    },
    {
      skip: !props.id,
    }
  );

  const statusOptions = useProgrammeStatusOptions();
  const programmeCategoryOptions = useProgrammeCategoryOptions();
  const programmeTargetOptions = useProgrammeTargetOptions();
  const programmeTypeOptions = useProgrammeTypeOptions();
  const programmeTypeOfActivityOptions = useProgrammeTypeOfActivityOptions();
  const history = useHistory();
  const isEBLRole = useRoleGuard([ROLE.EBL]);
  const isMultiplierRole = useRoleGuard([ROLE.MULTIPLIER]);

  const initialValues =
    props.id && programmeData
      ? {
          [PROGRAMME_FORM_FIELDS.Language]: props?.language || programmeData?.langcode || '',
          [PROGRAMME_FORM_FIELDS.DisplayTitle]: programmeData?.title || '',
          [PROGRAMME_FORM_FIELDS.ReferenceTitle]: programmeData?.referenceTitle || '',
          [PROGRAMME_FORM_FIELDS.ProgrammePromoter]: programmeData?.promoter || '',
          [PROGRAMME_FORM_FIELDS.PreviewText]: programmeData?.description?.summary || '',
          [PROGRAMME_FORM_FIELDS.ProgrammeDescription]: programmeData?.description?.value || '',
          [PROGRAMME_FORM_FIELDS.ProgrammeCategory]: String(programmeData?.programmeCategory?.id) || '',
          [PROGRAMME_FORM_FIELDS.ProgrammeTopics]:
            programmeData?.programmeTopics?.map((i) => String(i?.drupal_internal__tid)) || '',
          [PROGRAMME_FORM_FIELDS.ProgrammeType]: String(programmeData?.programmeType?.id) || '',
          [PROGRAMME_FORM_FIELDS.ProgrammeTarget]: String(programmeData?.programmeTarget?.id) || '',
          [PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart]: programmeData?.ageFrom || '',
          [PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd]: programmeData?.ageTo || '',
          [PROGRAMME_FORM_FIELDS.ProgrammeStartDate]: programmeData?.start ? parseISO(programmeData?.start) : '',
          [PROGRAMME_FORM_FIELDS.ProgrammeEndDate]: programmeData?.end ? parseISO(programmeData?.end) : '',
          [PROGRAMME_FORM_FIELDS.DestinationCountries]:
            programmeData?.destinationCountries?.map((i) => ({ value: i?.code, label: i?.title })) || '',
          [PROGRAMME_FORM_FIELDS.EligibleCountries]:
            programmeData?.eligibleCountries?.map((i) => ({ value: i?.code, label: i?.title })) || '',
          [PROGRAMME_FORM_FIELDS.DeadlinesStart]: programmeData?.nextDeadline?.map((i) => parseISO(i)),
          [PROGRAMME_FORM_FIELDS.DeadlinesEnd]: programmeData?.registrationDeadline
            ? parseISO(programmeData.registrationDeadline)
            : '',
          [PROGRAMME_FORM_FIELDS.IsTranslatable]: programmeData?.isTranslatable ? 'true' : 'false',
          [PROGRAMME_FORM_FIELDS.TypeOfActivity]: String(programmeData?.programmeTypeOfActivity?.id) || '',
          [PROGRAMME_FORM_FIELDS.Status]: programmeData?.programmeStatus
            ? statusOptions.find((i) => i.value === programmeData?.programmeStatus)
            : '',
          [PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated]: [],
          [PROGRAMME_FORM_FIELDS.Image]: {
            content: programmeData?.image?.uri?.url
              ? (process.env.REACT_APP_API_URL || '') + programmeData?.image?.uri?.url
              : '',
          },
        }
      : {
          [PROGRAMME_FORM_FIELDS.Language]: isEBLRole
            ? 'eu'
            : String(whoAmI?.office?.address?.countryCode).toLowerCase(),
          [PROGRAMME_FORM_FIELDS.DisplayTitle]: '',
          [PROGRAMME_FORM_FIELDS.ReferenceTitle]: '',
          [PROGRAMME_FORM_FIELDS.ProgrammePromoter]: '',
          [PROGRAMME_FORM_FIELDS.PreviewText]: '',
          [PROGRAMME_FORM_FIELDS.ProgrammeDescription]: '',
          [PROGRAMME_FORM_FIELDS.ProgrammeCategory]: programmeCategoryOptions[0]?.value,
          [PROGRAMME_FORM_FIELDS.ProgrammeTopics]: [],
          [PROGRAMME_FORM_FIELDS.ProgrammeType]: programmeTypeOptions[0]?.value,
          [PROGRAMME_FORM_FIELDS.ProgrammeTarget]: programmeTargetOptions[0]?.value,
          [PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart]: '',
          [PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd]: '',
          [PROGRAMME_FORM_FIELDS.ProgrammeStartDate]: '',
          [PROGRAMME_FORM_FIELDS.ProgrammeEndDate]: '',
          [PROGRAMME_FORM_FIELDS.DestinationCountries]: [],
          [PROGRAMME_FORM_FIELDS.EligibleCountries]: [],
          [PROGRAMME_FORM_FIELDS.DeadlinesStart]: [],
          [PROGRAMME_FORM_FIELDS.DeadlinesEnd]: '',
          [PROGRAMME_FORM_FIELDS.IsTranslatable]: 'true',
          [PROGRAMME_FORM_FIELDS.TypeOfActivity]: programmeTypeOfActivityOptions[0]?.value,
          [PROGRAMME_FORM_FIELDS.Status]: isMultiplierRole
            ? statusOptions.find((i) => i.value === PROGRAMME_STATUS.PENDING)
            : '',
          [PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated]: [],
          [PROGRAMME_FORM_FIELDS.Image]: '',
        };

  const handleSubmit = async (values: FormikValues) => {
    try {
      /**
       * If original programme was modified and user marked translations as outdated,
       * set content_translation_outdated = 1, so the backend would set all translations as outdated
       *
       * If translation was modified and the status is now published,
       * set content_translation_outdated = 0 to mark translation as up to date
       */
      let isTranslationOutdated = undefined;
      if (currentLanguage === defaultLanguage && values[PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated].includes('1')) {
        isTranslationOutdated = 1;
      } else if (values[PROGRAMME_FORM_FIELDS.Status].value === PROGRAMME_STATUS.PUBLISHED) {
        isTranslationOutdated = 0;
      }

      const response = await addProgramme({
        payload: {
          ...values,
          [PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated]: isTranslationOutdated,
        },
        id: props?.id || undefined,
        language: currentLanguage !== defaultLanguage ? currentLanguage : '',
        translationExists: programmeTranslations[currentLanguage]?.status !== TRANSLATION_STATUS.NOT_TRANSLATED,
      }).unwrap();

      if (response?.error && typeof response.error === 'string') {
        throw Error(String(response.error));
      } else if (response?.id) {
        // upload or remove a file if necessary
        if (values[PROGRAMME_FORM_FIELDS.Image]?.filename) {
          const uploadRes = await uploadFile({
            id: response?.id,
            type: 'programme',
            field: 'field_image',
            ...values[PROGRAMME_FORM_FIELDS.Image],
          }).unwrap();
          if (uploadRes?.error) {
            throw Error(uploadRes.error);
          }
        } else if (programmeData?.image?.id && !values[PROGRAMME_FORM_FIELDS.Image]?.content) {
          const deleteRes = await deleteFile({ id: response?.id, type: 'programme', field: 'field_image' }).unwrap();
          if (deleteRes?.error) {
            throw Error(deleteRes.error);
          }
        }

        toast.success(props.id ? 'Programme updated successfully' : 'Programme created successfully');
        history.push(
          NAV_ROUTES.PROGRAMME_DATABASE({ child: 'overview', id: response?.id, language: currentLanguage || undefined })
        );
      }
    } catch (e: any) {
      toast.error(e?.message || e?.error || e?.data?.error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <NewProgrammeForm
        id={props.id}
        language={String(props.language).toLowerCase()}
        isDefault={currentLanguage === defaultLanguage}
      />
    </Formik>
  );
};

const NewProgrammeForm = ({ id, language, isDefault }: { id?: string; language?: string; isDefault: boolean }) => {
  const isMultiplierRole = useRoleGuard([ROLE.MULTIPLIER]);
  const { values, setFieldValue, initialValues } = useFormikContext<{ [key: string]: any }>();
  const countryOptions = useCountryOptions();
  const statusOptions = useProgrammeStatusOptions().filter(
    (i) => !isMultiplierRole || i.value !== PROGRAMME_STATUS.PUBLISHED
  );
  const programmeCategoryOptions = useProgrammeCategoryOptions();
  const programmeTargetOptions = useProgrammeTargetOptions();
  const programmeTopicsOptions = useProgrammeTopicsOptions({ id: values[PROGRAMME_FORM_FIELDS.ProgrammeCategory] });
  const programmeTypeOptions = useProgrammeTypeOptions();
  const programmeTypeOfActivityOptions = useProgrammeTypeOfActivityOptions();

  const [, { isLoading }] = useAddProgrammeMutation();

  const category = values[PROGRAMME_FORM_FIELDS.ProgrammeCategory];

  const showActivityTypeOptions = useMemo(
    () =>
      values[PROGRAMME_FORM_FIELDS.ProgrammeCategory] ===
      programmeCategoryOptions?.find((i) => i.label === 'Volunteering')?.value,
    [values, programmeCategoryOptions]
  );

  const showDeadlineField = useMemo(
    () =>
      values[PROGRAMME_FORM_FIELDS.ProgrammeType] !==
      programmeTypeOptions?.find((i) => i.label === 'Ongoing opportunity')?.value,
    [values, programmeTypeOptions]
  );

  React.useEffect(() => {
    if (category === initialValues[PROGRAMME_FORM_FIELDS.ProgrammeCategory]) {
      setFieldValue(PROGRAMME_FORM_FIELDS.ProgrammeTopics, initialValues[PROGRAMME_FORM_FIELDS.ProgrammeTopics]);
    } else {
      setFieldValue(PROGRAMME_FORM_FIELDS.ProgrammeTopics, []);
    }
  }, [category, setFieldValue, initialValues]);

  return (
    <Form>
      <MasonryContentBlock
        title={`${id ? 'Edit' : 'Create a new'} programme`}
        masonryProps={{ breakpointCols: { default: 2, 1024: 1 } }}
      >
        <div>
          <FieldLabel id={PROGRAMME_FORM_FIELDS.Language} title="Language" subTitle="Publicly visible">
            <Field
              id={PROGRAMME_FORM_FIELDS.Language}
              name={PROGRAMME_FORM_FIELDS.Language}
              disabled={true}
              component={TextField}
            />
          </FieldLabel>
          <FieldLabel id={PROGRAMME_FORM_FIELDS.DisplayTitle} title="Display title" subTitle="Publicly visible.">
            <Field
              id={PROGRAMME_FORM_FIELDS.DisplayTitle}
              name={PROGRAMME_FORM_FIELDS.DisplayTitle}
              required
              component={TextField}
            />
          </FieldLabel>
          <FieldLabel
            id={PROGRAMME_FORM_FIELDS.ReferenceTitle}
            title="Reference title"
            subTitle="Only visible in the back office to provide more information if necessary."
          >
            <Field
              id={PROGRAMME_FORM_FIELDS.ReferenceTitle}
              name={PROGRAMME_FORM_FIELDS.ReferenceTitle}
              component={TextField}
            />
          </FieldLabel>
          <FieldLabel
            id={PROGRAMME_FORM_FIELDS.ProgrammePromoter}
            title="Programme promoter"
            subTitle="Publicly visible."
          >
            <Field
              id={PROGRAMME_FORM_FIELDS.ProgrammePromoter}
              name={PROGRAMME_FORM_FIELDS.ProgrammePromoter}
              component={TextField}
            />
          </FieldLabel>
          <FieldLabel
            id={PROGRAMME_FORM_FIELDS.PreviewText}
            title="Preview text"
            subTitle="Publicly visible on the preview card of the Opportunity Finder."
          >
            <Field
              id={PROGRAMME_FORM_FIELDS.PreviewText}
              name={PROGRAMME_FORM_FIELDS.PreviewText}
              component={TextField}
            />
          </FieldLabel>
          <FieldLabel
            id={PROGRAMME_FORM_FIELDS.ProgrammeDescription}
            title="Programme description"
            subTitle="Publicly visible at the programme description of the Opportunity Finder."
          >
            <Field
              id={PROGRAMME_FORM_FIELDS.ProgrammeDescription}
              name={PROGRAMME_FORM_FIELDS.ProgrammeDescription}
              toolbar={['formatting', 'blocks']}
              component={RichTextField}
            />
          </FieldLabel>
          <FieldLabel id="programme-category-label" title="Programme categories">
            <div role="group" aria-labelledby="programme-category-label">
              {programmeCategoryOptions.map((i, index) => (
                <Field
                  key={`programme-category-field-${index}`}
                  id={`${PROGRAMME_FORM_FIELDS.ProgrammeCategory}-${index}`}
                  name={PROGRAMME_FORM_FIELDS.ProgrammeCategory}
                  value={i?.value}
                  component={RadioField}
                >
                  {i?.label}
                </Field>
              ))}
            </div>
          </FieldLabel>
          <FieldLabel id="programme-topics-label" title="Programme topics">
            <div role="group" aria-labelledby="programme-topics-label">
              {programmeTopicsOptions.map((i, index) => (
                <Field
                  key={`programme-topic-field-${index}`}
                  id={`${PROGRAMME_FORM_FIELDS.ProgrammeTopics}-${index}`}
                  name={PROGRAMME_FORM_FIELDS.ProgrammeTopics}
                  value={i?.value}
                  component={CheckField}
                >
                  {i?.label}
                </Field>
              ))}
            </div>
          </FieldLabel>
          <FieldLabel id="programme-type-label" title="Programme type">
            <div role="group" aria-labelledby="programme-type-label">
              {programmeTypeOptions.map((i, index) => (
                <Field
                  key={`programme-type-field-${index}`}
                  id={`${PROGRAMME_FORM_FIELDS.ProgrammeType}-${index}`}
                  name={PROGRAMME_FORM_FIELDS.ProgrammeType}
                  value={i?.value}
                  component={RadioField}
                >
                  {i?.label}
                </Field>
              ))}
            </div>
          </FieldLabel>
          <FieldLabel id="target-group-label" title="Target group">
            <div role="group" aria-labelledby="target-group-label">
              {programmeTargetOptions.map((i, index) => (
                <Field
                  key={`target-group-field-${index}`}
                  id={`${PROGRAMME_FORM_FIELDS.ProgrammeTarget}-${index}`}
                  name={PROGRAMME_FORM_FIELDS.ProgrammeTarget}
                  value={i?.value}
                  component={RadioField}
                >
                  {i?.label}
                </Field>
              ))}
            </div>
          </FieldLabel>
          <div className="flex flex-wrap gap-8">
            <FieldLabel className="flex-1" id={PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart} title="Eligible age group">
              <Field
                id={PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart}
                name={PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart}
                component={TextField}
              />
            </FieldLabel>
            <FieldLabel className="flex-1" id={PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd} title="-">
              <Field
                id={PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd}
                name={PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd}
                component={TextField}
              />
            </FieldLabel>
          </div>
          <div className="flex gap-8">
            <DatePicker
              id={PROGRAMME_FORM_FIELDS.ProgrammeStartDate}
              name={PROGRAMME_FORM_FIELDS.ProgrammeStartDate}
              className="flex-1"
              title="Start and end date"
              dateFormat="MMMM d, yyyy HH:mm"
              timeFormat="HH:mm"
              monthsShown={2}
              showTimeSelect
              startDate={values[PROGRAMME_FORM_FIELDS.ProgrammeStartDate]}
              endDate={values[PROGRAMME_FORM_FIELDS.ProgrammeEndDate]}
              selected={values[PROGRAMME_FORM_FIELDS.ProgrammeStartDate]}
              selectsStart
              onChange={(date) => setFieldValue(PROGRAMME_FORM_FIELDS.ProgrammeStartDate, date)}
            />
            <DatePicker
              id={PROGRAMME_FORM_FIELDS.ProgrammeEndDate}
              name={PROGRAMME_FORM_FIELDS.ProgrammeEndDate}
              className="flex-1"
              title="-"
              dateFormat="MMMM d, yyyy HH:mm"
              timeFormat="HH:mm"
              monthsShown={2}
              showTimeSelect
              startDate={values[PROGRAMME_FORM_FIELDS.ProgrammeStartDate]}
              endDate={values[PROGRAMME_FORM_FIELDS.ProgrammeEndDate]}
              selected={values[PROGRAMME_FORM_FIELDS.ProgrammeEndDate]}
              selectsEnd
              onChange={(date) => setFieldValue(PROGRAMME_FORM_FIELDS.ProgrammeEndDate, date)}
            />
          </div>
          <Field
            id={`${PROGRAMME_FORM_FIELDS.DestinationCountries}-field`}
            name={PROGRAMME_FORM_FIELDS.DestinationCountries}
            title="Destination countries"
            options={countryOptions}
            component={SelectField}
            isMulti
            isSearchable
            selectAll
          />
          <Field
            id={`${PROGRAMME_FORM_FIELDS.EligibleCountries}-field`}
            name={PROGRAMME_FORM_FIELDS.EligibleCountries}
            title="Eligible countries"
            options={countryOptions}
            component={SelectField}
            isMulti
            isSearchable
            selectAll
          />
          {showDeadlineField && (
            <MultiField
              id={`${PROGRAMME_FORM_FIELDS.DeadlinesStart}`}
              name={`${PROGRAMME_FORM_FIELDS.DeadlinesStart}`}
              title="Deadlines"
              subTitle="Next deadline"
              component={DatePicker}
              items={values[PROGRAMME_FORM_FIELDS.DeadlinesStart]}
              itemInitialValue=""
              valuePropName="selected"
              componentSpecificProps={{
                dateFormat: 'MMMM d, yyyy HH:mm',
                timeFormat: 'HH:mm',
                monthsShown: 2,
                showTimeSelect: true,
              }}
            />
          )}
          <DatePicker
            id={PROGRAMME_FORM_FIELDS.DeadlinesEnd}
            name={PROGRAMME_FORM_FIELDS.DeadlinesEnd}
            subTitle="End of programme"
            dateFormat="MMMM d, yyyy HH:mm"
            timeFormat="HH:mm"
            monthsShown={2}
            showTimeSelect
            selected={values[PROGRAMME_FORM_FIELDS.DeadlinesEnd]}
            onChange={(date) => setFieldValue(PROGRAMME_FORM_FIELDS.DeadlinesEnd, date)}
          />
          <FieldLabel id="translation-label" title="Translations">
            <div role="group" aria-labelledby="translation-label">
              <Field
                id={`${PROGRAMME_FORM_FIELDS.IsTranslatable}-1`}
                name={PROGRAMME_FORM_FIELDS.IsTranslatable}
                value="true"
                component={RadioField}
              >
                Allow other national coordinators and multipliers from other countries to access this programme and
                translate it to their language.
              </Field>
              <Field
                id={`${PROGRAMME_FORM_FIELDS.IsTranslatable}-2`}
                name={PROGRAMME_FORM_FIELDS.IsTranslatable}
                value="false"
                component={RadioField}
              >
                Keep this programme available for users in my country only.
              </Field>
            </div>
          </FieldLabel>
          {showActivityTypeOptions && (
            <FieldLabel id="type-of-activity-label" title="Programme type of activity">
              <div role="group" aria-labelledby="type-of-activity-label">
                {programmeTypeOfActivityOptions.map((i, index) => (
                  <Field
                    key={`type-of-activity-field-${index}`}
                    id={`${PROGRAMME_FORM_FIELDS.TypeOfActivity}-${index}`}
                    name={PROGRAMME_FORM_FIELDS.TypeOfActivity}
                    value={i?.value}
                    component={RadioField}
                  >
                    {i?.label}
                  </Field>
                ))}
              </div>
            </FieldLabel>
          )}
          <Field
            disabled={isMultiplierRole}
            id={PROGRAMME_FORM_FIELDS.Status}
            name={PROGRAMME_FORM_FIELDS.Status}
            title="Status"
            options={statusOptions}
            component={SelectField}
            isSearchable
          />
        </div>
        <FileUpload
          className="md:ml-20"
          image={values[PROGRAMME_FORM_FIELDS.Image]?.content || undefined}
          onChange={(file) => setFieldValue(PROGRAMME_FORM_FIELDS.Image, file)}
        />
        {isDefault && (
          <Field
            id={PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated}
            name={PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated}
            value="1"
            component={CheckField}
          >
            Mark translations as outdated
          </Field>
        )}
      </MasonryContentBlock>
      <div className="flex justify-end mt-10">
        <Button
          variant="danger"
          className="mr-4"
          href={NAV_ROUTES.PROGRAMME_DATABASE({ child: 'overview', id: id, language: language })}
        >
          Discard changes
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? '...Saving' : 'Save changes'}
        </Button>
      </div>
    </Form>
  );
};

export default React.memo(ProgrammeForm);
